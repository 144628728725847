import React, { useState } from 'react'
import styled from 'styled-components'
const Container = styled.div`
  background-color: black;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const FlexContainer = styled.div`
  display: flex;
`
const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const MenuText = styled.p`
  margin-right: 24px;
  cursor: pointer;
`

const Title = styled.p`
  font-size: 36px;
`

const BORDER_COLOR = '#5c5757'
const App = () => {
  const [menu, setMenu] = useState('main')

  const mainComponent: { [key: string]: JSX.Element } = {
    main: <Main />,
    news: <News />,
    service: <Service />,
    about: <About />,
  }
  return (
    <Container>
      <Header setMenu={setMenu} />
      <div style={{ padding: '10%' }}>{mainComponent[menu]}</div>
    </Container>
  )
}

type HeaderProps = {
  setMenu: React.Dispatch<React.SetStateAction<string>>
}
const Header = ({ setMenu }: HeaderProps) => {
  return (
    <FlexContainer
      style={{
        flexDirection: 'column',
      }}
    >
      <FlexContainer
        style={{
          justifyContent: 'space-between',
          padding: '10px 16px',
          borderBottom: `1px solid ${BORDER_COLOR}`,
          alignItems: 'center',
        }}
      >
        <p style={{ fontWeight: 'bold', fontSize: '24px' }}>Stem</p>
        <FlexContainer>
          <MenuText onClick={() => setMenu('main')}>Home</MenuText>
          <MenuText onClick={() => setMenu('news')}>News</MenuText>
          <MenuText onClick={() => setMenu('service')}>Service</MenuText>
          <MenuText onClick={() => setMenu('about')}>About</MenuText>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  )
}

const Main = () => {
  return <p>Unleash the Potential of Your Business with Next-Level Web Services</p>
}

const News = () => {
  return (
    <FlexColumnContainer>
      <Title>News</Title>
      <FlexContainer>
        <p style={{ marginRight: '10%' }}>2022/12/29</p>
        <p>2022/12/29 ~ 2023/01/04まで年末年始休暇をいただきます。</p>
      </FlexContainer>
      <FlexContainer>
        <p style={{ marginRight: '10%' }}>2022/03/22</p>
        <p>株式会社Stem設立いたしました。</p>
      </FlexContainer>
    </FlexColumnContainer>
  )
}

const Service = () => {
  return (
    <FlexColumnContainer>
      <Title>Service</Title>
      <p>webサービス開発・コンサルティング</p>
    </FlexColumnContainer>
  )
}

const About = () => {
  return (
    <FlexColumnContainer>
      <Title>About</Title>
      <FlexContainer>
        <p style={{ width: '25%' }}>社名</p>
        <p>株式会社Stem</p>
      </FlexContainer>
      <FlexContainer>
        <p style={{ width: '25%' }}>設立</p>
        <p>2022年3月22日</p>
      </FlexContainer>
      <FlexContainer>
        <p style={{ width: '25%' }}>代表者</p>
        <p>木野雄希</p>
      </FlexContainer>
      <FlexContainer>
        <p style={{ width: '25%' }}>株主構成</p>
        <p>木野雄希</p>
      </FlexContainer>
      <FlexContainer>
        <p style={{ width: '25%' }}>資本金</p>
        <p>100万円</p>
      </FlexContainer>
      <FlexContainer>
        <p style={{ width: '25%' }}>事業概要</p>
        <p>webサービス開発・コンサルティング</p>
      </FlexContainer>
      <FlexContainer>
        <p style={{ width: '25%' }}>取引銀行</p>
        <p>住信SBIネット銀行</p>
      </FlexContainer>
    </FlexColumnContainer>
  )
}

export default App
