import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'

import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBTjhJYUT8jznJgG9iVYt55OruibwBRmus',
  authDomain: 'stem-corporate.firebaseapp.com',
  projectId: 'stem-corporate',
  storageBucket: 'stem-corporate.appspot.com',
  messagingSenderId: '566424083346',
  appId: '1:566424083346:web:7248ac9a25342cecc2ff4b',
  measurementId: 'G-014CM3TS6J',
}

const app = initializeApp(firebaseConfig)
if (process.env.NODE_ENV === 'production') {
  getAnalytics(app)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
